import React, { useState } from 'react';
import ProductComparisonCard from './productComparisonCard';
import styles from './productComparison.module.scss';
import useProductComparisonData from '../hooks/useProductComparisonData';
import RichText from './richText';
import TextWithBr from './textWithBr';
import classnames from 'classnames';

const ProductComparison = ({ title, description }) => {
  //fetch harcoded products data
  const productComparisonData = useProductComparisonData();
  //set hardcoded filters
  const productFilters = [
    { title: 'ELA', id: 'ela' },
    { title: 'Social Studies', id: 'social-studies' },
    { title: 'Science', id: 'science' },
  ];

  const [productSelected, setProductSelected] = useState(productFilters[0].id);

  const onChange = (productId) => setProductSelected(productId);

  const cardsToRender = productComparisonData.filter(
    (product) =>
      product.id === 'lite' ||
      product.id === 'essentials' ||
      product.id === productSelected
  );

  return (
    <div className={styles.productComparisonModule}>
      {title && (
        <TextWithBr className={styles.title} tag="h1" content={title} />
      )}

      {description && (
        <RichText className={styles.subtitle} content={description.json} />
      )}

      <div className={styles.filters}>
        {productFilters.map((product, index) => (
          <div
            onClick={() => onChange(product.id)}
            className={classnames(styles.filter, {
              [styles.filterSelected]: product.id === productSelected,
            })}
          >
            <p className={styles.filter__text} key={index}>
              {product.title}
            </p>
          </div>
        ))}
      </div>

      <div className={styles.cards}>
        {cardsToRender.map((product, index) => (
          <ProductComparisonCard key={index} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductComparison;
